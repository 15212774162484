<script>
	import Vue from 'vue';
	import BaseVue from '@frontend/Base.vue';
	import Gen from '../helper/Gen';
	import VueInstagram from 'vue-instagram'

	export default {
		extends: BaseVue,
		components: {
			VueInstagram
		},
		data() {
			return {
				static_cape: {},
				static_belli: {},
				instagram_cape: {},
				instagram_belli: {},
			}
		},
		computed: {
			breadCrumb() {
				return [{
					name: 'Home',
					link: {
						name: 'index'
					}
				}, {
					name: this.web.lbl_our_story,
					link: {
						name: 'Story'
					}
				}]
			}
		},
		async mounted() {
			await this.ready();
			this.$set(this.$root, 'page', this);
			if (typeof SEMICOLON != "undefined") SEMICOLON.documentOnReady.init()
			setTimeout(() => {
				$('[data-toggle="tooltip"]').tooltip();
				SEMICOLON.documentOnReady.init()
				SEMICOLON.slider.sliderParallax()
				SEMICOLON.widget.carousel()
			}, 100)
			$(document).ready(() => {
				AOS.init()
			})

			$(document).ready(function () {
				$('[data-toggle="popover"]').popover({
					trigger: 'hover',
					html: true,
					content: function () {
						return '<img class="img-fluid" src="' + $(this).data('img') + '" />';
					},
					title: 'Toolbox'
				})
			});
			$(document).on('click', '.mfp-close', () => {
				this.$router.push('/')
			});

			this.staticCape()
			this.staticBelli()
			this.staticInstagramCape()
			this.staticInstagramBelli()
			// this.mrUserTokenCape()
			// this.mrUserTokenBelli()
		},
		methods: {
			staticCape() {
				this.$root.topProgress.done()
				Gen.apirest("/static-content", {
					id: 5
				}, (err, resp) => {
					if (err) console.log(err)
					this.static_cape = resp.data
				})
			},
			staticBelli() {
				this.$root.topProgress.done()
				Gen.apirest("/static-content", {
					id: 6
				}, (err, resp) => {
					if (err) console.log(err)
					this.static_belli = resp.data
				})
			},
			mrUserTokenCape() { Gen.apirest("/master-setting", { id: 17 }, (err, resp) => {
					if (err) console.log(err)
					this.getFeedInstagramCape(resp.data.value)
				})
			},
			mrUserTokenBelli() { Gen.apirest("/master-setting", { id: 19 }, (err, resp) => {
					if (err) console.log(err)
					this.getFeedInstagramBelli(resp.data.value)
				})
			},
			staticInstagramCape() {
				this.$root.topProgress.done()
				Gen.apirest("/cape-feed-instagran", {}, (err, resp) => {
					if (err) console.log(err)
					this.instagram_cape = resp.data
				})
			},
			staticInstagramBelli() {
				this.$root.topProgress.done()
				Gen.apirest("/belli-feed-instagran", {}, (err, resp) => {
					if (err) console.log(err)
					this.instagram_belli = resp.data
				})
			},
			getFeedInstagramBelli(user_token) {
				$.ajax({
					url: 'https://graph.instagram.com/me/media?fields=id,media_type,media_url,username,timestamp&access_token=' + user_token,
					method: 'get',
					success: (data) => {
						console.log(data.data)
						this.$set(this, "instagram_belli", data.data)
					}
				});
			},
			getFeedInstagramCape(user_token) {
				$.ajax({
					url: 'https://graph.instagram.com/me/media?fields=id,media_type,media_url,username,timestamp&access_token=' + user_token,
					method: 'get',
					success: (data) => {
						console.log(data.data)
						this.$set(this, "instagram_cape", data.data)
					}
				});
			}
		},
		watch: {}
	};
</script>
<template>
	<div id="content">
		<section class="section nobottommargin notopmargin">
			<div class="container">
				<BreadCrumb :data="breadCrumb"></BreadCrumb>
				<div class="heading-title">
					<h2>{{web.lbl_our_story}}</h2>
				</div>
				<div class="row" data-aos="fade-up" data-aos-duration="1000">
					<div class="col-12 text-center">
						<img v-if="static_cape.show_image=='Y'" :src="uploader(static_cape.image1)"
							alt="Cape Discovery Logo">
					</div>
					<div class="col-lg-6">
						<div class="about-story pl-0">
							<p v-html="static_cape.description"></p>
						</div>
					</div>
					<div class="col-lg-6">
						<div class="about-story collage">
							<div class="form-row">
								<div class="col-6 col-md-4" v-for="(v,k) in instagram_cape" :key="k">
									<div class="as-img">
										<!-- <a :href="v.post_link" target="_blank">
											<VImg :lazySrc="v.thumbnail" alt="Feed Instagram Cape Discovery"
												data-aos="fade-up" data-aos-duration="1000"></VImg>
										</a> -->
										<a href="javascript:;">
											<VImg :lazySrc="v.thumbnail" alt="Feed Instagram Cape Discovery"
												data-aos="fade-up" data-aos-duration="1000"></VImg>
										</a>
									</div>
								</div>
								<div class="col-12">
									<div class="as-img">
										<img v-if="static_cape.show_image2=='Y'" :src="uploader(static_cape.image2)"
											alt="About Images" data-aos="fade-up" data-aos-duration="1000">
										<!-- <VImg v-if="static_cape.show_image2=='Y'" :lazySrc="uploader(static_cape.image2)" alt="About Images" data-aos="fade-up" data-aos-duration="1000"></VImg> -->
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section class="section nobottommargin notopmargin nobg">
			<div class="container">

				<div class="row" data-aos="fade-up" data-aos-duration="1000">
					<div class="col-12 text-center">
						<img :src="uploader(static_belli.image1)" alt="Bellissimo Logo">
					</div>
					<div class="col-lg-6">
						<div class="about-story pl-0">
							<h4>{{static_belli.title}}</h4>
							<p v-html="static_belli.description"></p>
						</div>
					</div>
					<div class="col-lg-6">
						<div class="about-story collage">
							<div class="form-row">
								<div class="col-6 col-md-4" v-for="(v,k) in instagram_belli" :key="k">
									<div class="as-img" style="height: 100%;">
										<a href="javascript:;">
											<!-- <img :src="v.thumbnail" alt="Feed Instagram Bellissimmo" data-aos="fade-up" data-aos-duration="1000"> -->
											<!-- <VImg :lazySrc="v.thumbnail" class="img_full"
												alt="Feed Instagram Bellissimmo" data-aos="fade-up"
												data-aos-duration="1000"></VImg> -->
											<VImg :lazySrc="v.thumbnail" class="img_full"
												alt="Feed Instagram Bellissimmo" data-aos="fade-up"
												data-aos-duration="1000"></VImg>
										</a>
									</div>
								</div>
								<div class="col-md-12">
									<div class="as-img">
										<img v-if="static_belli.show_image2=='Y'" :src="uploader(static_belli.image2)"
											alt="About Images" data-aos="fade-up" data-aos-duration="1000">
										<!-- <VImg v-if="static_belli.show_image2=='Y'" :lazySrc="uploader(static_belli.image2)" alt="About Images" data-aos="fade-up" data-aos-duration="1000"></VImg> -->
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>